import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { map, share } from "rxjs/operators";
import { Observable } from "rxjs";
import {
  IDictionaryBody,
  IDictionaryParams,
  ISelectItem,
} from "ngx-strong-frontend-lib/interfaces";
import {
  IArmGroupDictionaryParams,
  ITaskTemplateDictionaryParams,
  IUiObjectDictionaryParams,
} from "@core/interfaces/dictionary";

@Injectable({
  providedIn: "root",
})
export class DictionaryApiService {
  constructor(private httpClient: HttpClient) {}

  // Справочник "Роли пользователя"
  public getRolesDictionary(
    params: IDictionaryParams
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(
        `${environment.API_URL}/dictionary/page/user-role`,
        params
      )
      .pipe(share());
  }

  // Справочник "Пользователи"
  public getUserDictionary(
    params: IDictionaryParams
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(
        `${environment.API_URL}/dictionary/page/user`,
        params
      )
      .pipe(share());
  }

  // Справочник "Тип события"
  public getEventTypeDictionary(
    params: IDictionaryParams
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(
        `${environment.API_URL}/dictionary/page/event-type`,
        params
      )
      .pipe(share());
  }

  // Справочник "Категория события"
  public getEventCategoryDictionary(
    params: IDictionaryParams
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(
        `${environment.API_URL}/dictionary/page/event-category`,
        params
      )
      .pipe(share());
  }

  // Справочник "Тип события" для микросервисов
  public getEventTypeDictionaryForEventLog(
    remoteService: string
  ): Observable<ISelectItem[]> {
    return this.httpClient
      .get<ISelectItem[]>(
        `${environment.API_URL}/admin/event/log/dict/${remoteService}/event/type`
      )
      .pipe(share());
  }

  // Справочник "Категория события" для микросервисов
  public getEventCategoryDictionaryForEventLog(
    remoteService: string
  ): Observable<ISelectItem[]> {
    return this.httpClient
      .get<ISelectItem[]>(
        `${environment.API_URL}/admin/event/log/dict/${remoteService}/event/category`
      )
      .pipe(share());
  }

  // Справочник "Элементы UI"
  public getUiObjectDictionary(
    params: IUiObjectDictionaryParams
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(
        `${environment.API_URL}/dictionary/page/ui-object`,
        params
      )
      .pipe(share());
  }

  // Справочник "Тип ОС" для микросервисов
  public getOsTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(
        `${environment.API_URL}/dictionary/drop-down/os-type`,
        {}
      )
      .pipe(share());
  }

  // Справочник "Шаблон задач"
  public getTaskTemplateDictionary(
    params: ITaskTemplateDictionaryParams
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(
        `${environment.API_URL}/dictionary/page/task-template`,
        params
      )
      .pipe(share());
  }

  // Справочник "Группа АРМ"
  public getArmGroupDictionary(
    params: IArmGroupDictionaryParams
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(
        `${environment.API_URL}/dictionary/page/workstation-group`,
        params
      )
      .pipe(share());
  }
  // Справочник «События АРМ»
  public getWorkstationEventDictionary(
    workstationEventTypeIds: number[] = null
  ): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(
        `${environment.API_URL}/dictionary/drop-down/workstation-event`,
        {
          workstationEventTypeIds: workstationEventTypeIds,
        }
      )
      .pipe(share());
  }
  // Справочник «Тип события АРМ»
  public getWorkstationEventTypeDictionary(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(
        `${environment.API_URL}/dictionary/drop-down/workstation-event-type`,
        {}
      )
      .pipe(share());
  }

  // Справочник "Разрядность ОС"
  public getOsBitSystemList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(
        `${environment.API_URL}/dictionary/drop-down/os-bit-system`,
        {}
      )
      .pipe(share());
  }

  // Справочник "Версия ОС" для поиска
  public getOsVersionListForSearch(
    params: IDictionaryParams
  ): Observable<ISelectItem[]> {
    return this.httpClient
      .post<IDictionaryBody>(
        `${environment.API_URL}/dictionary/page/os-version`,
        params
      )
      .pipe(
        map((result: IDictionaryBody) => {
          return result && result.elements ? result.elements : [];
        }),
        share()
      );
  }

  // Справочник "Версия агента" для поиска
  public getAgentVersionListForSearch(
    params: IDictionaryParams
  ): Observable<ISelectItem[]> {
    return this.httpClient
      .post<IDictionaryBody>(
        `${environment.API_URL}/dictionary/page/agent-version`,
        params
      )
      .pipe(
        map((result: IDictionaryBody) => {
          return result && result.elements ? result.elements : [];
        }),
        share()
      );
  }

  // Справочник "Статус выполнения задачи" для журнала задач
  public getWorkstationTaskStateList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(
        `${environment.API_URL}/workstation/task/dict/state/list`,
        {
          codes: ["RMM_WORKSTATION_TASK"],
          isShortName: false,
        }
      )
      .pipe(share());
  }

  // Справочник "Статус выполнения задачи" для журнала задач
  public getTaskJournalStateList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/task/log/dict/state/list`, {
        codes: ["TASK_LOG"],
        isShortName: false,
      })
      .pipe(share());
  }

  // Справочник "Статусы УРМ"
  public getArmStateList(
    isShortName: boolean = false
  ): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(
        `${environment.API_URL}/workstation/dict/state/list`,
        {
          codes: ["WORKSTATION"],
          isShortName: isShortName,
        }
      )
      .pipe(share());
  }

  // Справочник "дистрибутивы Linux"
  public getLinuxDistributionDictionary(
    params: IDictionaryParams
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(
        `${environment.API_URL}/dictionary/page/distribution`,
        params
      )
      .pipe(share());
  }

  // Справочник "Архитектура Linux"
  public getLinuxArchitectureDictionary(
    params: IDictionaryParams
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(
        `${environment.API_URL}/dictionary/page/architecture`,
        params
      )
      .pipe(share());
  }

  // Справочник "Тип установки"
  public getOSInstallationType(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(
        `${environment.API_URL}/dictionary/drop-down/install-type`,
        {}
      )
      .pipe(share());
  }

  public getKeyStateList(isShortName: boolean = false) {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/workstation/dict/state/list`, {
        codes: ['CERTIFICATE'], isShortName: isShortName
      }
    ).pipe(share());
  }

  public getCertificateTypeList() {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/certificate-type`, null
    ).pipe(share());
  }

  public getEventCollectionStateList(isShortName: boolean = false) {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/workstation/dict/state/list`, {
        codes: ['WORKSTATION_POLICY'], isShortName
      }
    ).pipe(share());
  }

  /**
   * Справочник «Язык»
   */
  public getLanguageList() {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/language`, null
    ).pipe(share());
  }

  // Справочник "Типы инвентаризации"
  public getInventoryTypeDictionary(
    params: IDictionaryParams
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(
        `${environment.API_URL}/dictionary/page/inventory-type`,
        params
      )
      .pipe(share());
  }

  // Справочник "Дерево инвертаризации"
  public getInventoryTreeDictionary(
    params: IDictionaryParams
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(
        `${environment.API_URL}/dictionary/page/inventory-tree`,
        params
      )
      .pipe(share());
  }

  // Справочник "Статус задачи для УРМ"
  public getTaskStateList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/task/dict/state/list`, {
        codes: ["TASK"],
        isShortName: false,
      })
      .pipe(share());
  }

  // Справочник Статус шаблонов задач(пользовательские/стандартные)
  public getTaskTemplateStateList(): Observable<ISelectItem[]> {
    return this.httpClient
    .post<ISelectItem[]>(`${environment.API_URL}/task/dict/state/list`, {
      codes: ["TASK_TEMPLATE"],
      isShortName: false,
    })
    .pipe(share());
  }
}
