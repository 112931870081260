import { SystemParamsService } from '@core/services/system-params.service';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Location } from "@angular/common";
import { AuthService } from "@core/services/auth/auth.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "@core/services/user.service";
import { UserApiService } from "@core/services/api/user-api.service";
import { LocalStorageService } from "ngx-strong-frontend-lib/services/local-storage";
import { VisibilityService } from "ngx-strong-frontend-lib/services/visibility";
import { HttpErrorResponse } from "@angular/common/http";
import {REDIRECT_TO_KEY, USER_ACCESS} from "@app/app.enums";
import { SYSTEM_PARAMETER_NAME } from 'ngx-strong-frontend-lib/enums';
import { forkJoin } from 'rxjs';

@Injectable()
export class AuthorizationGuard  {

  private errors: string[];

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private localStorage: LocalStorageService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private userService: UserService,
    private userApiService: UserApiService,
    private systemParamsService: SystemParamsService,
    private visibilityService: VisibilityService
  ) {
    this.translateService.get('LOGIN.ERRORS').subscribe((result: string[]) => {
      this.errors = result;
    });
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const code: string = route.queryParamMap.get('code');
    const stateFromRedirect: string = route.queryParamMap.get('state');
    const stateString: string = this.localStorage.getObjectByName('state');
    if (code && stateFromRedirect && stateString && stateString === stateFromRedirect) {
      this.authService.getTokenFromCode(code).subscribe({
        next: (res) => {
          this.systemParamsService.updateSystemParams();
          const userInfo$ = this.userApiService.getUserInfo();
          const userAccessObjects$ = this.userApiService.getUserAccessObjects();
          const userAccessTree$ = this.userApiService.getUserAccessTree();
          forkJoin([userInfo$, userAccessObjects$, userAccessTree$]).subscribe({
            next: ([userInfo, accessObjects, accessTree]) => {
              this.userService.userInfo = userInfo;
              this.userService.setAccessObjects(accessObjects, accessTree);
              this.navigateToRedirect();
              return false;
            },
            error: error => {
              this.userService.clearUserSession();
              this.toastr.error(
                error?.error?.message,
                this.errors['ERROR_USER']
              );
              this.navigateToRedirect();
              return false;
            }
          })
        },
        error: (error) => {
          if (error instanceof HttpErrorResponse && error.status === 400) {
            this.navigateToNoAccess(state.url);
          }
          else {
            this.navigateToLogin();
          }
          return false;
        }
      });
    }
    else {
      this.navigateToLogin();
      return false;
    }
  }

  private navigateToNoAccess(url: string): void {
    this.router.navigate(['/no-access']).then(() => {
      this.location.replaceState(url);
    });
  }

  private navigateToLogin(): void {
    const authUrl: string = this.localStorage.getObjectByName(SYSTEM_PARAMETER_NAME.AUTH_URL);
    if (authUrl) {
      document.location.href = `${authUrl}/auth`;
    }
  }

  private navigateToRedirect() {
    const redirectTo = this.localStorage.getObjectByName(REDIRECT_TO_KEY);
    this.localStorage.setObjectByName(REDIRECT_TO_KEY, null);
    const hasAccessArms: boolean = this.visibilityService.isVisible(USER_ACCESS['ARM_LIST']);
    this.router.navigate([redirectTo ? redirectTo : hasAccessArms ? '/arm/arms' : '/main']);
  }
}
