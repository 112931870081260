import {Component, Input} from "@angular/core";
import {ITerminalMessage} from "@core/interfaces/terminal-stomp";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-task-result-modal',
  templateUrl: './task-result-modal.component.html',
  styleUrls: ['./task-result-modal.component.scss'],
})
export class TaskResultModalComponent {

  @Input() result: string[] = null;

  public title: string = '';
  public messages: string[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService,
  ) {
    this.translateService.get(['TASK.ARM_TASK_JOURNAL.TASK_RESULT']).subscribe(res => {
      this.title = res['TASK.ARM_TASK_JOURNAL.TASK_RESULT']['TITLE'];
    });
  }

  ngOnInit() {
    if (this.result && this.result.length > 0) {
      this.result.forEach((item: string) => {
        this.messages.push(item);
      });
    } else {
      this.messages = [];
    }
  }

  public cancel(): void {
    this.messages = [];
    this.activeModal.close();
  }

}
