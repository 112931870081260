import { DownloadStatesInterceptor } from './services/interceptors/download-states.interceptor';
import { ContentTypeInterceptor } from '@core/services/interceptors/content-type.interceptor';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from '@core/services/auth/auth.service';
import { AuthGuard } from '@core/guards/auth-guard.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateInitializerFactory } from '@core/services/translate-initializer.service';
import { OAuth2Service } from "@core/services/auth/oauth2.service";
import { AccessGuardService } from "@core/guards/access-guard.service";
import { AuthorizationGuard } from "@core/guards/authorization-guard.service";
import { TokenInterceptorConfig, TokenInterceptorModule } from 'ngx-strong-frontend-lib/services/token-interceptor';
import { tokenInterceptorConfigFactory } from './functions/token-interceptor-config-factory';
import { TokenSyncConfig, TokenSyncModule } from 'ngx-strong-frontend-lib/services/token-sync';
import { tokenSyncConfigFactory } from './functions/token-sync-config-factory';
import { BroadcastModule } from 'ngx-strong-frontend-lib/services/broadcast';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TokenInterceptorModule.forRoot(
      {
        provide: TokenInterceptorConfig,
        useFactory: tokenInterceptorConfigFactory,
        deps: [
          Injector
        ]
      }
    ),
    TokenSyncModule.forRoot(
      {
        provide: TokenSyncConfig,
        useFactory: tokenSyncConfigFactory,
        deps: [
          AuthService
        ]
      }
    ),
    BroadcastModule
  ],
  providers: [
    AuthGuard,
    AccessGuardService,
    AuthorizationGuard,
    { provide: AuthService, useClass: OAuth2Service },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentTypeInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DownloadStatesInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: TranslateInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
  ]
})
export class CoreModule { }
