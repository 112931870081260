import { QuicklinkModule } from 'ngx-quicklink';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { TextMaskModule } from 'angular2-text-mask';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from "@solidexpert/ng-click-outside";
import * as dayjs from 'dayjs';
import * as dayjsUtc from 'dayjs/plugin/utc';
import * as dayjsFormats from 'dayjs/plugin/customParseFormat';
import * as dayjsRu from 'dayjs/locale/ru';
import * as dayjsQuarter from 'dayjs/plugin/quarterOfYear';
import * as dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as dayjsDuration from 'dayjs/plugin/duration';
import * as dayjsArraySupport from 'dayjs/plugin/arraySupport';
import { AuthorizationComponent } from "@shared/components/authorization/authorization.component";
import { HeaderComponent } from "@shared/components/header/header.component";
import { AccordionItemModule } from 'ngx-strong-frontend-lib/components/accordion-item';
import { ActionMenuModule } from 'ngx-strong-frontend-lib/components/action-menu';
import { BasePageWithChildsModule } from 'ngx-strong-frontend-lib/components/base-page-with-childs';
import { BreadcrumbsModule } from 'ngx-strong-frontend-lib/components/breadcrumbs';
import { ButtonModule } from 'ngx-strong-frontend-lib/components/button';
import { CalendarModule } from 'ngx-strong-frontend-lib/components/calendar';
import { CheckboxModule } from 'ngx-strong-frontend-lib/components/checkbox';
import { CheckboxListModule } from 'ngx-strong-frontend-lib/components/checkbox-list';
import { ConfirmModalModule } from 'ngx-strong-frontend-lib/components/confirm-modal';
import { DownloadProgressModule } from 'ngx-strong-frontend-lib/components/download-progress';
import { EventLogCardModule } from 'ngx-strong-frontend-lib/components/event-log-card';
import { FilesListModule } from 'ngx-strong-frontend-lib/components/files-list';
import { InputModule } from 'ngx-strong-frontend-lib/components/input';
import { LockEditableModule } from 'ngx-strong-frontend-lib/components/lock-editable';
import { LoaderModule } from 'ngx-strong-frontend-lib/components/loader';
import { MenuModule } from 'ngx-strong-frontend-lib/components/menu';
import { MultiDictionaryModule } from 'ngx-strong-frontend-lib/components/multi-dictionary';
import { MultiSelectModule } from 'ngx-strong-frontend-lib/components/multi-select';
import { NoSelectStubModule } from 'ngx-strong-frontend-lib/components/no-select-stub';
import { NoAccessStubModule } from 'ngx-strong-frontend-lib/components/no-access-stub';
import { NsiDictionarySelectListModule } from 'ngx-strong-frontend-lib/components/nsi-dictionary-select-list';
import { PasswordInputModule } from 'ngx-strong-frontend-lib/components/password-input';
import { RadioboxListModule } from 'ngx-strong-frontend-lib/components/radiobox-list';
import { RoleAccessTreeModule } from 'ngx-strong-frontend-lib/components/role-access-tree';
import { RoundProgressBarModule } from 'ngx-strong-frontend-lib/components/round-progress-bar';
import { SearchFieldModule } from 'ngx-strong-frontend-lib/components/search-field';
import { SelectSearchListModule } from 'ngx-strong-frontend-lib/components/select-search-list';
import { SimpleDictionaryModule } from 'ngx-strong-frontend-lib/components/simple-dictionary';
import { SimpleSelectModule } from 'ngx-strong-frontend-lib/components/simple-select';
import { SwitchModule } from 'ngx-strong-frontend-lib/components/switch';
import { TabsModule } from 'ngx-strong-frontend-lib/components/tabs';
import { TextareaModule } from 'ngx-strong-frontend-lib/components/textarea';
import { ToastrModule } from 'ngx-strong-frontend-lib/components/toastr';
import { UniversalTreeModule } from 'ngx-strong-frontend-lib/components/universal-tree';
import { UniversalTreeItemModule } from 'ngx-strong-frontend-lib/components/universal-tree-item';
import { DisableControlModule } from 'ngx-strong-frontend-lib/directives/disable-control';
import { FormatDatePipeModule } from 'ngx-strong-frontend-lib/pipes/format-date';
import { IsVisiblePipeModule } from 'ngx-strong-frontend-lib/pipes/is-visible';
import { RouteParamDirectiveModule } from 'ngx-strong-frontend-lib/directives/route-param';
import { LogoComponent } from './components/logo/logo.component';
import { TerminalModule } from 'ngx-strong-frontend-lib/components/terminal';
import { PopupContainerModule } from 'ngx-strong-frontend-lib/components/popup-container';
import { FocusTrapDirective, FocusTrapModule } from 'ngx-strong-frontend-lib/directives/focus-trap';
import {RoleFunctionsBlockComponent} from "@shared/components/role-functions-block/role-functions-block.component";
import {TaskResultModalComponent} from "@shared/components/task-result-modal/task-result-modal.component";
import { SaveButtonsComponent } from '@shared/components/save-buttons/save-buttons.component';

dayjs.extend(dayjsQuarter);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsFormats);
dayjs.extend(dayjsIsSameOrBefore);
dayjs.extend(dayjsIsSameOrAfter);
dayjs.extend(dayjsDuration);
dayjs.extend(dayjsArraySupport);
dayjs.locale(dayjsRu);

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // TextMaskModule,
        TranslateModule,
        RouterModule,
        NgbModule,
        ContextMenuModule,
        ClickOutsideModule,
        QuicklinkModule,
        // lib
        AccordionItemModule,
        ActionMenuModule,
        BasePageWithChildsModule,
        BreadcrumbsModule,
        ButtonModule,
        CalendarModule,
        CheckboxModule,
        CheckboxListModule,
        ConfirmModalModule,
        DownloadProgressModule,
        EventLogCardModule,
        FilesListModule,
        InputModule,
        LoaderModule,
        LockEditableModule,
        MenuModule,
        MultiDictionaryModule,
        MultiSelectModule,
        NoSelectStubModule,
        NoAccessStubModule,
        NsiDictionarySelectListModule,
        PasswordInputModule,
        RadioboxListModule,
        RoleAccessTreeModule,
        RoundProgressBarModule,
        SearchFieldModule,
        SelectSearchListModule,
        SimpleDictionaryModule,
        SimpleSelectModule,
        SwitchModule,
        TabsModule,
        TextareaModule,
        ToastrModule,
        UniversalTreeModule,
        UniversalTreeItemModule,
        DisableControlModule,
        FormatDatePipeModule,
        IsVisiblePipeModule,
        RouteParamDirectiveModule,
        TerminalModule,
        PopupContainerModule,
        FocusTrapModule,
    ],
    declarations: [
        AuthorizationComponent,
        HeaderComponent,
        LogoComponent,
        TaskResultModalComponent,
        RoleFunctionsBlockComponent,
        SaveButtonsComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        // TextMaskModule,
        NgbModule,
        QuicklinkModule,
        ClickOutsideModule,
        ReactiveFormsModule,
        TranslateModule,
        ContextMenuModule,
        SaveButtonsComponent,
        // lib
        AccordionItemModule,
        ActionMenuModule,
        BasePageWithChildsModule,
        BreadcrumbsModule,
        ButtonModule,
        CalendarModule,
        CheckboxModule,
        CheckboxListModule,
        ConfirmModalModule,
        DownloadProgressModule,
        EventLogCardModule,
        FilesListModule,
        InputModule,
        LoaderModule,
        LockEditableModule,
        MenuModule,
        MultiDictionaryModule,
        MultiSelectModule,
        NoSelectStubModule,
        NoAccessStubModule,
        NsiDictionarySelectListModule,
        PasswordInputModule,
        RadioboxListModule,
        RoleAccessTreeModule,
        RoundProgressBarModule,
        SearchFieldModule,
        SelectSearchListModule,
        SimpleDictionaryModule,
        SimpleSelectModule,
        SwitchModule,
        TabsModule,
        TextareaModule,
        ToastrModule,
        UniversalTreeModule,
        UniversalTreeItemModule,
        DisableControlModule,
        FormatDatePipeModule,
        IsVisiblePipeModule,
        RouteParamDirectiveModule,
        PopupContainerModule,
        FocusTrapDirective,
        // components
        AuthorizationComponent,
        HeaderComponent,
        LogoComponent,
        TaskResultModalComponent,
        RoleFunctionsBlockComponent
    ]
})
export class SharedModule { }
