import {Component, EventEmitter, Input, Output} from "@angular/core";
import {IAdminRoleTreeItem} from "ngx-strong-frontend-lib/interfaces";

interface IRoleFunction extends IAdminRoleTreeItem {
  isChecked: boolean;
  disabled: boolean;
}

enum RoleFunctionsCode {
  AgentFileCopyAllowed = 'RA_CLIPBOARD_FILE_COPY_FROM', // Копирование файлов
  AgentInteractiveMode = 'RA_INTERACTIVE', // Интерактивный режим
  AgentSilentMode = 'RA_SILENT', // Тихий режим
  AgentClipboardAllowed = 'RA_CLIPBOARD_FROM', // Буфер обмена
  UserConfirmationRequired = 'RA_BYPASS_CONFIRMATION' // Не требуется разрешение
}

@Component({
  selector: 'app-role-functions-block',
  templateUrl: './role-functions-block.component.html',
  styleUrls: ['./role-functions-block.component.scss']
})
export class RoleFunctionsBlockComponent {

  @Input() set functions(value: IAdminRoleTreeItem[]) {
    this.functionsArray = value ?? [];
    this.prepareFunctions();
  };
  @Input() set functionAccess(value: number[]) {
    this.selectedFunctionAccess = value ?? [];
    this.prepareFunctions();
  };
  @Input() readonly: boolean = true; // режим чтения
  @Output() OnChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  public functionsForView: IRoleFunction[] = [];
  private functionsArray: IAdminRoleTreeItem[] = [];
  private selectedFunctionAccess: number[] = [];

  constructor() {
  }

  private prepareFunctions() {
    this.functionsForView = this.functionsArray && this.functionsArray.length > 0
      ? this.functionsArray.map((item: IAdminRoleTreeItem) => {
        if (this.selectedFunctionAccess && this.selectedFunctionAccess.length > 0) {
          return {...item,
            ...{isChecked: this.selectedFunctionAccess.includes(Number(item.id)) ?? false,
              disabled: this.prepareDisabled(item.code)}};
        } else {
          return {...item, ...{isChecked: false,
              disabled: this.prepareDisabled(item.code)}};
        }
      })
      : [];
  }

  public changeValue(value: boolean, func: IRoleFunction) {
    func.isChecked = value;
    // если Тихий режим = true - Не требуется разрешение на подключение = true
    if (func.code === RoleFunctionsCode.AgentSilentMode) {
      const userConfirmationRequired = this.functionsForView.find((item: IRoleFunction) => item.code === RoleFunctionsCode.UserConfirmationRequired);
      if (userConfirmationRequired) {
        if (func.isChecked) {
          userConfirmationRequired.isChecked = true;
          userConfirmationRequired.disabled = true;
        } else {
          userConfirmationRequired.disabled = false;
        }
      }
    }
    // если Копирование файлов = true - Буфер обмена = true
    if (func.code === RoleFunctionsCode.AgentFileCopyAllowed && func.isChecked) {
      const agentClipboard = this.functionsForView.find((item: IRoleFunction) => item.code === RoleFunctionsCode.AgentClipboardAllowed);
      if (agentClipboard) {
        agentClipboard.isChecked = true;
      }
    }
    // если Буфер обмена = false - Копирование файлов = false
    if (func.code === RoleFunctionsCode.AgentClipboardAllowed && !func.isChecked) {
      const agentFileCopy = this.functionsForView.find((item: IRoleFunction) => item.code === RoleFunctionsCode.AgentFileCopyAllowed);
      if (agentFileCopy) {
        agentFileCopy.isChecked = false;
      }
    }
    // если Интерактивный режим = false - Буфер обмена = false
    if (func.code === RoleFunctionsCode.AgentInteractiveMode) {
      const agentClipboard = this.functionsForView.find((item: IRoleFunction) => item.code === RoleFunctionsCode.AgentClipboardAllowed);
      if (agentClipboard) {
        if (!func.isChecked) {
          agentClipboard.isChecked = false;
          agentClipboard.disabled = true;
        } else {
          agentClipboard.disabled = false;
        }
      }
      const agentFileCopy = this.functionsForView.find((item: IRoleFunction) => item.code === RoleFunctionsCode.AgentFileCopyAllowed);
      if (agentFileCopy) {
        if (!func.isChecked) {
          agentFileCopy.isChecked = false;
          agentFileCopy.disabled = true;
        } else {
          agentFileCopy.disabled = false;
        }
      }
    }
    this.OnChange.emit(this.prepareValues());
  }

  private prepareValues(): number[] {
    if (this.functionsForView && this.functionsForView.length > 0) {
      const selectedFunctions = this.functionsForView.filter((item:IRoleFunction) => !!item.isChecked);
      return selectedFunctions && selectedFunctions.length > 0
        ? selectedFunctions.map((item: IRoleFunction) => {return Number(item.id)})
        : [];
    } else {
      return [];
    }
  }

  private prepareDisabled(code: string): boolean {
    if (code && code === RoleFunctionsCode.UserConfirmationRequired) {
      const agentSilentMode = this.functionsArray.find((item: IRoleFunction) => item.code === RoleFunctionsCode.AgentSilentMode);
      return agentSilentMode && agentSilentMode.id
        && this.selectedFunctionAccess && this.selectedFunctionAccess.length > 0
        && this.selectedFunctionAccess.includes(Number(agentSilentMode.id));
    } else if (code && (code === RoleFunctionsCode.AgentFileCopyAllowed
      || code === RoleFunctionsCode.AgentClipboardAllowed)) {
      const agentInteractiveMode = this.functionsArray.find((item: IRoleFunction) => item.code === RoleFunctionsCode.AgentInteractiveMode);
      return agentInteractiveMode && agentInteractiveMode.id
        && ((this.selectedFunctionAccess && this.selectedFunctionAccess.length > 0
        && !this.selectedFunctionAccess.includes(Number(agentInteractiveMode.id)))
          || !this.selectedFunctionAccess || this.selectedFunctionAccess.length === 0);
    } else {
      return false;
    }
  }
}
